import React from "react";
import theme from "theme";
import { Theme, Link, Text, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				404 - Page not found
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/back.jpg?v=2022-01-08T11:10:00.783Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/himmm.png?v=2020-10-03T22:34:30.191Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu>
			<Override slot="text">
				<Link href="https://ternoxgames.com/" color="#000000" text-decoration-line="initial">
					Ternoxgames.com
				</Link>
			</Override>
			<Override slot="menuOverride2" font="700 16px/24px " />
		</Components.Menuu>
		<Section min-height="300px" height="100%" padding="100px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text margin="20px 0 0 0" as="h2" font="--headline1" md-font="--headline2">
				404 - Page not found
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0" />
			<Image
				height="64px"
				src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22:35:08.842Z"
				margin="16px 0px 0px 0px"
				width="64px"
				srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/smile_big.png?v=2020-10-03T22%3A35%3A08.842Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Link
				href="https://ternoxgames.com/"
				text-decoration-line="initial"
				hover-background="--color-darkL1"
				display="inline-block"
				font="700 16px/24px --fontFamily-sans"
				background="--color-primary"
				padding="14px 24px 14px 24px"
				margin="45px 3px 3px 3px"
				color="#F7FBFF"
				border-radius="2px"
			>
				TO MAIN PAGE
			</Link>
		</Section>
	</Theme>;
});